import getData from "@/__main__/get-data.mjs";
import ValorantPlayerActSummariesModel from "@/data-models/valorant-player-act-summaries.mjs";
import ValorantPlayerAgentStats from "@/data-models/valorant-player-agent-stats.mjs";
import ValorantPlayerMapStats from "@/data-models/valorant-player-map-stats.mjs";
import ValorantPlayerStats from "@/data-models/valorant-player-stats.mjs";
import ValorantPlayerWeaponStats from "@/data-models/valorant-player-weapon-stats.mjs";
import * as API from "@/game-val/api.mjs";
import { EXCLUDED_QUEUES } from "@/game-val/constants.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import isValidNameTag from "@/game-val/is-valid-nametag.mjs";
import { isPrivateProfile } from "@/game-val/profile-utils.mjs";
import { separateNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devWarn } from "@/util/dev.mjs";

async function fetchActReviewData([nameTag, actId], searchParams) {
  const { name, tag } = separateNameTag(nameTag);
  if (!nameTag || !name || !tag || !isValidNameTag(nameTag))
    return devWarn("Invalid nameTag", nameTag);

  await fetchConstants();
  const player = await fetchProfile(name, tag);

  const isPrivate = isPrivateProfile(player);

  if (isPrivate) return devWarn("Private profile", nameTag);
  if (!player || !player.valorantProfile?.region) {
    devWarn("Mandatory info is missing from profile data", player);
    return;
  }

  const isConsole = searchParams.get("console") === "1";
  const platformPath = getPlatformPath(isConsole);

  const puuid = player.puuid;
  const region = player.valorantProfile?.region;
  const queueKey = "overall";

  const promises = [];
  const headers = await getAuthorizationHeaders();

  const getActSummaries = getData(
    API.getActSummaries({ puuid }),
    ValorantPlayerActSummariesModel,
    ["val", "actSummaries", puuid],
    {
      shouldFetchIfPathExists: false,
      headers,
    },
  );

  const getPlayerStats = getData(
    API.getPlayerStats({
      puuid,
      actUuid: actId,
      region,
    }),
    ValorantPlayerStats,
    ["val", "playerActStats", puuid, actId, platformPath],
    { headers },
  );

  const getPlayerAgentStats = getData(
    API.getAgentStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      excludeQueues: queueKey === "overall" ? EXCLUDED_QUEUES : [],
      console: isConsole,
    }),
    ValorantPlayerAgentStats,
    ["val", "playerAgentStats", puuid, actId, platformPath, queueKey],
    { headers },
  );

  const getPlayerWeaponStats = getData(
    API.getWeaponStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      console: isConsole,
    }),
    ValorantPlayerWeaponStats,
    ["val", "playerWeaponStats", puuid, actId, platformPath, queueKey],
    { headers },
  );

  const getPlayerMapStats = getData(
    API.getMapStats({
      puuid,
      actUuid: actId,
      region,
      queue: queueKey === "overall" ? null : queueKey,
      console: isConsole,
    }),
    ValorantPlayerMapStats,
    ["val", "playerMapStats", puuid, actId, platformPath, queueKey],
    { headers },
  );

  promises.push(
    getActSummaries,
    getPlayerStats,
    getPlayerAgentStats,
    getPlayerWeaponStats,
    getPlayerMapStats,
  );

  return Promise.all(promises);
}

export default fetchActReviewData;
